@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



@media print {
  @page {
    size: 8.5in 12in;
    margin: .3cm auto; /* Adjust as needed for your layout */
  }

  body {
    visibility: hidden;
    margin: 0;
    padding: 0;
    font-size: 12pt; /* or whatever size you prefer */
  }

  .main-worksheet, .main-worksheet * {
    visibility: visible;
    color: black; /* Ensures that text is black on printing even if it's styled differently on screen */
  }

  .main-worksheet {
   
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    margin: 0;
    padding: 0;
    line-height: normal; /* Adjust line height for better text readability */
    /* page-break-inside: avoid;
    break-inside: avoid-column; */
  }


  button, .print-hidden {
    display: none !important;
  }

  /* Print only the main worksheet content */
  .print-only {
    display: block !important;
  }

  .footer {
    position: static !important;
    display: flex !important; /* Overriding Tailwind's 'hidden' class */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 4px;
    font-size: 0.75rem; /* This is equivalent to 'text-xs' in Tailwind */
    color: #6b7280; /* This is equivalent to 'text-gray-500' in Tailwind */
    background-color: white; /* Ensuring the background is white */
    z-index: 1000; /* Make sure it's above other elements */
    gap: 5px; /* Adjust this value as needed to control the space between items */
}

}



/* @media print {
  @page {
    size: 8.5in 11in;
    margin: 0in; 
  }
  body {
    visibility: hidden;
    margin: 0;
    padding: 0;
  }
  .main-worksheet, .main-worksheet * {
    visibility: visible;
  }
  .main-worksheet {
    position: absolute;
    left: 0;
    top: 0;
    width: 8.5in;
    height: 11in;
  }
  button {
    display: none;
  }
} */